export const TICKER_PREFETCH_SIZE = '5';
export const TICKER_SCROLL_SIZE = '100';
export const TICKER_MATCH_CARD_WIDTH = 324;
export const HOMEPAGE_COLUMN_NUMBER_OF_WIDGETS = 10;
export const MATCH_CARDS_BUCKET_PATH = 'matchcards';
export const COURT_PRIORITY = [
	'cc',
	'incc',
	's',
	'gs',
	'sc',
	's1',
	'gs1',
	'sc1',
	's2',
	'gs2',
	'sc2',
	's3',
	'gs3',
	'sc3',
	's4',
	'gs4',
	'sc4',
	's5',
	'gs5',
	'sc5',
	's6',
	'gs6',
	'sc6'
];

export const USER_IMPRESSION_KEY = 'user-impression-key';
export const BLOG_LABEL = 'Pickleball Hub';
export const GOOGLE_AUTOCOMPLETE_SCRIPT_ID = 'google-script';
export const TINYMCE_EDITOR_KEY = '2fhjogepu3pkb5q18hb6hzxgg2ojov07jq1mukahjnp7y0my';
