'use client';

import { faArrowToTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

export default function ScrollToTop() {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = () => {
		if (window.pageYOffset > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const hasHiddenClass = () => {
		if (!isVisible) return 'hidden';
		return;
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		<div className="z-100 fixed bottom-3 right-3 sm:bottom-8 sm:right-10">
			<button
				onClick={scrollToTop}
				className={`ease-[cubic-bezier(0.4,0,0.2,1)] inline-flex items-center justify-center rounded-lg bg-gray-50 px-4 py-2.5 text-base font-semibold leading-5 
        text-gray-700 shadow-[inset_0_0_0_1px_#d0d5dd] transition-all duration-300
        hover:text-gray-800 hover:shadow-[inset_0_0_0_1px_#d0d5dd]
        ${hasHiddenClass()}`}
			>
				<span>
					<FontAwesomeIcon className="mr-2" icon={faArrowToTop} />
				</span>
				Back to Top
			</button>
		</div>
	);
}
